import React, { useState } from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import NavigationBar from "../../components/NavigationBar"
import Modal from "../../components/Modal"
import profileKirstie from "../../images/profile-kirstie.png"
import profileJustin from "../../images/profile-justin.png"
import profileKerry from "../../images/profile-kerry.png"

import "./style.scss"

const TeamMemberCard = ({ imageUrl, ctaUrl, name, role, children }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      {show && (
        <Modal
          title={name}
          body={children}
          handleClose={() => setShow(false)}
        />
      )}
      <div className="teamCard">
        <img src={imageUrl} alt={name} />
        <h2 className="name">{name}</h2>
        <h3 className="role">{role}</h3>
        <button className="button cta" onClick={() => setShow(true)}>
          Learn More
        </button>
      </div>
    </>
  )
}

const About = () => {
  return (
    <Layout>
      <SEO title="Our Team" />
      <div className="ourTeamPageContainer">
        <NavigationBar />
        <div className="banner">
          <div className="container">
            <h1>Our Team</h1>
            <p className="summary">Not all superheroes need to wear masks.</p>
          </div>
        </div>
        <div className="mainContent">
          <div className="container">
            <div className="team">
              <div className="row">
                <div className="teamMember">
                  <TeamMemberCard
                    name="Kirstie Loft"
                    role="Academy Manager &amp; Martial Arts Chief Instructor"
                    imageUrl={profileKirstie}
                  >
                    <p>
                      Kirstie plays a vital role in the Academy, joining us in
                      January 2019 to teach Kids Martial Arts classes and
                      provide support to the Medway HQ.
                    </p>
                    <p>
                      She started training in Krav Maga in early 2018 but brings
                      years of Martial Arts experience including competing at a
                      national level and instructing kids classes in Taekwondo.
                    </p>
                    <p>Qualifications:</p>
                    <ul>
                      <li>Krav Maga Practitioner Level 3</li>
                      <li>2nd Dan black belt in Taekwondo</li>
                      <li>2nd Dan black belt in Kaizendo</li>
                      <li>Krav Maga Kids Instructor</li>
                      <li>Martial Arts Instructor</li>
                      <li>DBS Checked</li>
                      <li>BTEC Level 3 Award in Self Defence Instruction</li>
                    </ul>
                  </TeamMemberCard>
                </div>

                <div className="teamMember">
                  <TeamMemberCard
                    name="Justin Bailey"
                    role="Instructor"
                    imageUrl={profileJustin}
                  >
                    <p>
                      Justin began training in Krav Maga with the Academy in
                      2015, seeking the skills necessary to protect himself and
                      his family should the need arise. He was also motivated by
                      the fitness aspect of the training.
                    </p>
                    <p>
                      Justin holds qualifications allowing him to work with our
                      youngest students, teens &amp; adults. He has previously
                      trained in Karate and Kickboxing and is an avid gym-goer.
                    </p>
                    <p>Qualifications:</p>
                    <ul>
                      <li>Krav Maga Instructor, Graduate Level 4</li>
                      <li>Krav Maga Kids &amp; Teens Instructor</li>
                      <li>Martial Arts Instructor</li>
                      <li>Tactical Shooting Course Level 1</li>
                      <li>
                        BTEC Level 3 Advanced Award in Self Defence Instruction
                      </li>
                      <li>DBS Checked</li>
                    </ul>
                  </TeamMemberCard>
                </div>

                <div className="teamMember">
                  <TeamMemberCard
                    name="Kerry Melbourne"
                    role="Programme Director"
                    imageUrl={profileKerry}
                  >
                    <p>
                      Kerry joins the Academy as Programme Director. She is
                      responsible for holding initial consultations, upgrading
                      existing members, as well as assisting with student
                      onboarding and providing a stress-free joining process.
                    </p>
                  </TeamMemberCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
